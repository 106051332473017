// @ts-strict-ignore
// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { QuestionnaireResponse } from '../questionnaire-response.model';
import { QuestionnaireResponseService } from '../questionnaire-response.service';

@Component({
  selector: 'app-questionnaire-response-entrypoint',
  templateUrl: './questionnaire-response-entrypoint.component.html',
  styleUrls: ['./questionnaire-response-entrypoint.component.scss'],
})
export class QuestionnaireResponseEntrypointComponent implements OnInit {
  questionnaireResponse$: Observable<QuestionnaireResponse>;

  constructor(
    private questionnaireResponseService: QuestionnaireResponseService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(({ questionnaireResponse }: { questionnaireResponse: QuestionnaireResponse }) => {
      this.questionnaireResponse$ = of(questionnaireResponse);
    });
  }
}
