// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { QuestionnaireResponse } from './questionnaire-response.model';
import { QuestionnaireResponseService } from './questionnaire-response.service';
import { inject } from '@angular/core';

export const QuestionnaireResponseResolver: ResolveFn<QuestionnaireResponse> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const questionnaireResponseId = route.params.questionnaireResponseId;
  return inject(QuestionnaireResponseService).getOne$(questionnaireResponseId, { expand: ['questionnaire', 'client'] });
};
