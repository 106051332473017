// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { EntityService } from '../core/entity/entity.service';
import { QuestionSelect } from '../question/question.model';
import { AlertService } from '../shared/alert.service';
import {
  QuestionResponse,
  QuestionResponseBreakdown,
  QuestionResponseSelect,
  QuestionResponseSelectMultiple,
} from './question-response.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionResponseService extends EntityService<QuestionResponse> {
  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getOrCreateByQuestionnaireResponseAndQuestion$(
    questionnaireResponseId: number,
    questionId: number,
  ): Observable<QuestionResponse> {
    const params = {
      questionnaire_response_id: questionnaireResponseId.toString(),
      question_id: questionId.toString(),
    };

    const req$ = super.getAll$(params).pipe(
      mergeMap((responses: QuestionResponse[]) => {
        if (responses.length === 1) {
          return of(responses[0]);
        } else if (responses.length > 1) {
          throw new Error(
            `More than one question response found for questionnaireResponseId(${questionnaireResponseId}), questionId(${questionId})`,
          );
        } else {
          // No question response exists. Create it now.
          return this.createQuestionResponse$(questionnaireResponseId, questionId);
        }
      }),
    );

    return this.handleGetOrCreateResponse(req$, {
      log: `Get or create ${this.singularIdentifier()} by questionnaireResponseId(${questionnaireResponseId}), questionId(${questionId}) failed:`,
      nice: `Failed to get or create ${this.singularIdentifier()}`,
    });
  }

  createQuestionResponse$(questionnaireResponseId: number, questionId: number): Observable<QuestionResponse> {
    const payload = { questionnaireResponseId, questionId };
    // Assert as `any` to get around typescript type checks. Here, the web-api backend expects payload in the above form; but it will return
    // a proper model of `QuestionResponse`
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.addOne$(payload as any);
  }

  updateOne$(id: number, qr: QuestionResponse): Observable<QuestionResponse> {
    // Need to cleanup sub question responses as they may be outdated comparing against the current selected options. For an option previously
    // selected and now unselected; its sub question response need to be removed prior to saving to database.
    if (qr.subQuestionResponses && qr.subQuestionResponses.length > 0) {
      const question = qr.question as QuestionSelect;
      let selectedOptionIds: number[];

      if (question.multiple) {
        selectedOptionIds = (qr as QuestionResponseSelectMultiple).optionIds || [];
      } else {
        const optionId = (qr as QuestionResponseSelect).optionId;
        selectedOptionIds = optionId == null ? [] : [optionId];
      }

      // Get the sub questions where responses are required to be saved.
      const subQuestionsRequired: number[] = selectedOptionIds
        .map(optionId => question.options.find(o => o.id === optionId).subQuestion?.id)
        .filter(subQuestionId => subQuestionId != null);

      qr.subQuestionResponses = qr.subQuestionResponses.filter(sqr => subQuestionsRequired.includes(sqr.question.id));
    }

    return super.updateOne$(id, qr);
  }

  getQuestionResponsesBreakdown$(questionnaireResponseIds: number[]): Observable<QuestionResponseBreakdown[]> {
    const params: { questionnaireResponseIds: number[] } = {
      questionnaireResponseIds,
    };

    const url = this.getWebApiEndpoint('breakdown');

    const req$ = this.httpClient.post<QuestionResponseBreakdown[]>(url, params);

    return this.handleGetOrCreateResponse(req$, {
      log: `Request ${this.singularIdentifier()} to get the breakdown failed`,
      nice: `Failed to request ${this.pluralIdentifier()} breakdown`,
    });
  }

  pluralIdentifier(): string {
    return 'question-responses';
  }

  singularIdentifier(): string {
    return 'question-response';
  }
}
