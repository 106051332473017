// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { QuestionType, QuestionSelect, QuestionSelectOption } from 'src/app/question/question.model';
// import { QuestionnaireResponseType } from 'src/app/questionnaire-response/questionnaire-response.type';
import { getEnumKeyFromValue } from 'src/app/shared/util';
import { FileInfo } from 'src/app/storage/file-info.model';
import {
  QuestionResponse,
  QuestionResponseSelect,
  QuestionResponseSelectMultiple,
  QuestionResponseText,
} from '../question-response.model';
import { QuestionResponseService } from '../question-response.service';

@Component({
  selector: 'app-question-response',
  templateUrl: './question-response.component.html',
  styleUrls: ['./question-response.component.scss'],
})
export class QuestionResponseComponent implements OnInit {
  @ViewChild('form')
  form: NgForm;

  @Input()
  questionnaireResponseId: number;

  @Input()
  questionId: number;

  @Input()
  editable: boolean;

  @Input()
  showPrevious: boolean;

  @Output()
  next: EventEmitter<unknown> = new EventEmitter();

  @Output()
  previous: EventEmitter<unknown> = new EventEmitter();

  questionResponse$: Observable<
    QuestionResponse | QuestionResponseText | QuestionResponseSelect | QuestionResponseSelectMultiple
  >;

  QuestionType = QuestionType;

  // type: QuestionnaireResponseType;

  files: FileInfo[];

  constructor(
    // @Inject(QuestionnaireResponseType) type: QuestionnaireResponseType,
    private questionResponseService: QuestionResponseService,
    private route: ActivatedRoute,
  ) {
    // this.type = type;
  }

  ngOnInit(): void {
    this.questionResponse$ = this.questionResponseService.getOrCreateByQuestionnaireResponseAndQuestion$(
      this.questionnaireResponseId,
      this.questionId,
    );
  }

  update(questionResponse: QuestionResponse, event: EventEmitter<unknown>): void {
    // Form inputs not editable or form is not changed. Nothing to save, perform event action (go next or previous question)
    if (!this.editable || !this.form.dirty) {
      event.emit();
      return;
    }

    // Navigating back to previous question. If form inputs validation is invalid, we don't save anything to server but allow navigating backwards.
    if (event === this.previous && this.form.invalid) {
      event.emit();
      return;
    } else {
      this.questionResponseService
        .updateOne$(questionResponse.id, questionResponse)
        .pipe(first())
        .subscribe(() => event.emit());
    }
  }

  displayOption(question: QuestionSelect, questionResponse: QuestionResponseSelect): string {
    return question.options.find(o => o.id === questionResponse.optionId)?.text;
  }

  displayOptions(question: QuestionSelect, questionResponse: QuestionResponseSelectMultiple): string {
    return question.options
      .filter(o => questionResponse.optionIds?.find(ro => ro === o.id))
      ?.map(o => o.text)
      .join('\n');
  }

  changeMultipleOption(
    questionResponse: QuestionResponseSelectMultiple,
    option: QuestionSelectOption,
    selected: boolean,
  ): void {
    if (!questionResponse.optionIds) {
      questionResponse.optionIds = [];
    }

    // Add the option
    if (selected && !questionResponse.optionIds.includes(option.id)) {
      questionResponse.optionIds.push(option.id);
    }

    // Remove the option
    if (!selected) {
      questionResponse.optionIds = questionResponse.optionIds.filter(o => o !== option.id);
    }

    // Delete the optionIds from the question response to fire the required validator on the form
    if (questionResponse.optionIds.length === 0) {
      delete questionResponse.optionIds;
    }

    // ngModel is not used for `optionIds`, so manually mark the form as dirty.
    // And also trigger value update and validation on our hidden `options` input that performs the
    // validation for `optionIds` (required)
    this.form.controls.options.markAsDirty();
    this.form.controls.options.updateValueAndValidity();
  }

  onFilesLoaded(files: FileInfo[]) {
    this.files = files;
  }

  getEnumKeyFromValue(value: QuestionType) {
    return getEnumKeyFromValue(QuestionType, value);
  }

  /**
   * Whether for the current selected options in the question response, does any option have a sub question?
   */
  hasSubQuestion(qr: QuestionResponse, option: QuestionSelectOption): false | QuestionResponse {
    if (qr.question.type !== getEnumKeyFromValue(QuestionType, QuestionType.SELECT) || !option.subQuestion) {
      return false;
    }

    const q = qr.question as QuestionSelect;
    let optionIds: number[];
    if (q.multiple) {
      // The current selected options.
      optionIds = (qr as QuestionResponseSelectMultiple).optionIds || [];
    } else {
      // The current selected option.
      const optionId = (qr as QuestionResponseSelect).optionId;
      optionIds = optionId != null ? [optionId] : [];
    }

    const exists = optionIds.includes(option.id);

    if (!exists) {
      return false;
    }

    // Find the option's subquestion response.
    const subQuestionResponse = qr.subQuestionResponses.find(sqr => sqr.question.id === option.subQuestion.id);
    return subQuestionResponse;
  }
}
