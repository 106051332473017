<!--
 Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<ng-container *ngIf="questionnaireResponse$ | async as questionnaireResponse">
  <div class="wrapper">
    <div class="content w-100">
      <app-collapsable-panel [name]="'Welcome to the ' + questionnaireResponse.questionnaire.name + ' powered by Fair Supply'" colour="#412fd5">
        <div *ngIf="!questionnaireResponse.submitted" class="block">
          <h2 class="mb-4">Welcome to the {{ questionnaireResponse.questionnaire.name }} powered by Fair Supply</h2>

          <p class="mb-2">We have been engaged by {{ questionnaireResponse.client.name }} to assess ESG risk along their operations and supply chains. Because you are one of {{ questionnaireResponse.client.name }} suppliers we request that you complete this ESG supplier self assessment questionnaire.</p>
          <p class="mb-2">So that {{ questionnaireResponse.client.name }} can finalise their supply chain risk assessment, we would greatly appreciate the completed survey by {{ questionnaireResponse.dueOn | date }}. If the information is not collected, we may not be able to action our risk analysis for our clients in an efficient and timely manner and may be required to contact you again with another survey and corresponding collection notice.</p>
          <p class="mb-2">Even in circumstances where {{ questionnaireResponse.companyName }} is not a mandatory reporting entity under any modern slavery, climate or nature-related disclosure regulation, or has minimal risks in its business operations or supply chain, the completion of the survey is an important part of {{ questionnaireResponse.client.name }}'s due diligence processes.</p>
          <p class="mb-2">Fair Supply Analytics Pty Ltd ABN 33 637 115 587 is collecting {{ questionnaireResponse.companyName }}'s information for the purpose of processing your responses to the survey as part of our ESG risk analysis for our customer, being a procurer of your goods and/or services. This may also include disclosing that information to {{ questionnaireResponse.client.name }}.</p>
          <p class="mb-2">We will use that information for that purpose, and we may use it as part of our risk assessments for our other clients should they also procure goods and services from you. We may also disclose the information collected to our other clients to facilitate a more efficient analysis of their ESG risk.</p>
          <p class="mb-2">You acknowledge that in responding to the supplier survey you consent to us disclosing that information to {{ questionnaireResponse.client.name }} and our other clients for those purposes.</p>
          <p class="mb-2">In the event that you do not wish to have the responses shared with our clients other than {{ questionnaireResponse.client.name }}, you may opt out by unchecking the consent box prior to submission.</p>
          <p class="mb-2">We otherwise use, disclose and handle personal information in accordance with our Privacy Policy. Our <a href="https://fairsupply.com/privacy-policy" target="_blank">Privacy Policy</a> also contains information regarding the contact details of our privacy officer and how individuals can seek access to and correct their personal information or raise a privacy concern with us.</p>

          <div class="mt-5">
            <a class="btn btn-primary" [routerLink]="['start']" queryParamsHandling="preserve">Start</a>
          </div>
        </div>

        <div *ngIf="questionnaireResponse.submitted" class="block">
          <p class="text-center">Thank you. Your responses are submitted.</p>
        </div>
      </app-collapsable-panel>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
