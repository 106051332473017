<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<ng-container *ngIf="questionResponse$ | async as qr">
  <form class="mt-4" #form="ngForm">
    <div class="form-group">
      <ng-container *ngIf="qr.question; let q">
        <div>{{ q.text }}</div>

        <ng-container *ngIf="q.type === getEnumKeyFromValue(QuestionType.TEXT)">
          <div *ngIf="editable">
            <textarea class="form-control" name="text{{ qr.id }}" [(ngModel)]="qr.text" rows="4" required #text="ngModel"></textarea>
            <div *ngIf="text.dirty && text.invalid" class="alert text-danger">
              <div *ngIf="text.errors.required">A response is required</div>
            </div>
          </div>

          <div *ngIf="!editable">
            <div class="text-preserve-lines">{{ qr.text }}</div>
          </div>
        </ng-container>

        <ng-container *ngIf="q.type === getEnumKeyFromValue(QuestionType.SELECT)">
          <div *ngIf="editable">
            <ng-container *ngIf="q.multiple">
              <input type="hidden" name="options" [ngModel]="qr.optionIds" required #options="ngModel" />
              <div *ngFor="let option of q.options; let i = index" class="form-check">
                <input type="checkbox" class="form-check-input" name="option{{ i }}" (change)="changeMultipleOption(qr, option, $event.target.checked)" [value]="option.id" [checked]="qr.optionIds?.includes(option.id)" #option{{i}} id="option{{ i }}" />
                <label class="form-check-label" for="option{{ i }}">{{ option.text }}</label>
              </div>
              <div *ngIf="options.dirty && options.invalid" class="alert text-danger">
                <div *ngIf="options.errors.required">A response is required</div>
              </div>
            </ng-container>
            <ng-container *ngIf="!q.multiple">
              <select class="form-control" name="option{{ qr.id }}" [(ngModel)]="qr.optionId" required #option="ngModel">
                <option *ngFor="let option of q.options" [ngValue]="option.id">{{ option.text }}</option>
              </select>
              <div *ngIf="option.dirty && option.invalid" class="alert text-danger">
                <div *ngIf="option.errors.required">A response is required</div>
              </div>
            </ng-container>
          </div>

          <div *ngIf="!editable">
            <ng-container *ngIf="q.multiple">
              <div class="text-preserve-lines">{{ displayOptions(q, qr) }}</div>
            </ng-container>
            <ng-container *ngIf="!q.multiple">
              <div class="text-preserve-lines">{{ displayOption(q, qr) }}</div>
            </ng-container>
          </div>

          <ng-container *ngFor="let option of q.options">
            <ng-container *ngIf="hasSubQuestion(qr, option); let subQuestionResponse">
              <app-sub-question-response [form]="form" [question]="option.subQuestion" [editable]="editable" [questionResponse]="subQuestionResponse"></app-sub-question-response>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="mt-5">
      <button type="button" class="btn btn-outline mr-4" *ngIf="showPrevious" (click)="update(qr, previous)">Back</button>
      <button type="button" class="btn btn-primary mr-4" [disabled]="!form.valid" (click)="update(qr, next)">Next</button>
    </div>
  </form>

  <div class="accordion mt-5" id="accordion">
    <div class="card">
      <div class="card-header" id="supportingDocuments">
        <button class="btn btn-link btn-lg collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseDocuments" aria-expanded="false" aria-controls="collapseDocuments">
          Supporting Documents <span *ngIf="files">({{ files.length }})</span>
        </button>
      </div>
      <div id="collapseDocuments" class="collapse" aria-labelledby="supportingDocuments" data-bs-parent="#accordion">
        <div class="card-body p-4">
          <app-storage-viewer entityName="question-responses" [entityId]="qr.id" [showFolders]="false" (filesLoaded)="onFilesLoaded($event)" [canUpload]="editable" [canDelete]="editable"></app-storage-viewer>
        </div>
      </div>
    </div>
  </div>
</ng-container>
