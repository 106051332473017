// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Question, QuestionType } from 'src/app/question/question.model';
import { QuestionService } from 'src/app/question/question.service';
import { QuestionnaireSection } from 'src/app/questionnaire-section/questionnaire-section.model';
import { QuestionnaireSectionService } from 'src/app/questionnaire-section/questionnaire-section.service';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QuestionnaireResponse } from '../questionnaire-response.model';
import { QuestionnaireResponseService } from '../questionnaire-response.service';

@Component({
  selector: 'app-questionnaire-response',
  templateUrl: './questionnaire-response.component.html',
  styleUrls: ['./questionnaire-response.component.scss'],
})
export class QuestionnaireResponseComponent implements OnInit {
  questionnaireResponse$: Observable<QuestionnaireResponse>;
  questionnaireSections$: Observable<QuestionnaireSection[]>;
  currentSectionIndex: number;
  currentQuestionIndex: number;
  progress: number;
  page: 'about' | 'questionnaire' | 'submit';
  showSubmit: boolean;
  QuestionType = QuestionType;

  protected obligationsOptions = [
    'Australia - Modern Slavery Act 2018 (Cth)',
    'UK - Modern Slavery Act 2015',
    'France - Duty of Vigilance Law 2017',
    'Netherlands - Child Labor Due Diligence Law 2019',
    'Germany - Act on Corporate Due Diligence Obligations in Supply Chains 2021',
    'Norway - Transparency Act 2021',
    'Switzerland -  Ordinance on Due Diligence and Transparency in Relation to Minerals and Metals from Conflict-affected Areas and Child Labour',
    'EU - Conflict Minerals Regulation',
    'California - Transparency in Supply Chains Act 2010',
    'Canada - Fighting against Forced Labour and Child Labour in Supply Chains Act',
    'US - Dodd-Frank Wall Street Reform and Consumer Act of 2010',
  ];
  protected restrictionsOptions = [
    'Your product(s) have been subject to restriction(s) under the Uyghur Forced Labour Prevent Act (Public Law No. 117-78)',
    'Your company has been named on the Uyghur Forced Labour Prevent Act (Public Law No. 117-78) Entity List',
    'Your products have been issued with a section 307 Withhold and Release Order under the US Tariff Act',
  ];
  protected showAboutPage = false;

  private totalQuestionCount: number;

  readonly routeData$ = this.route.data.pipe(takeUntilDestroyed());

  constructor(
    private questionnaireResponseService: QuestionnaireResponseService,
    private questionnaireSectionService: QuestionnaireSectionService,
    private questionService: QuestionService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.currentQuestionIndex = 0;
    this.currentSectionIndex = 0;
    this.progress = 0;

    this.routeData$.subscribe(({ questionnaireResponse }: { questionnaireResponse: QuestionnaireResponse }) => {
      this.questionnaireResponse$ = of(questionnaireResponse).pipe(
        tap(qr => {
          this.showAboutPage = !qr.submitted || qr.companyName !== null;
          this.page = this.showAboutPage ? 'about' : 'questionnaire';
        }),
        shareReplay(1),
      );

      this.questionnaireSections$ = this.questionnaireSectionService
        .getAllByQuestionnaire$(questionnaireResponse.questionnaire.id)
        .pipe(
          switchMap((sections: QuestionnaireSection[]) => {
            // Get all sections' questions.
            const sectionIds = sections.map(section => section.id);
            return this.questionService.getAllByQuestionnaireSections$(sectionIds).pipe(
              map((questions: Question[]) => {
                // Group questions according to their sections via section Id
                const groupByQuestionnaireSection: { [id: number]: Question[] } = questions.reduce(
                  (rv, q: Question) => {
                    const sId = q.questionnaireSection.id;
                    (rv[sId] = rv[sId] || []).push(q);
                    return rv;
                  },
                  {},
                );

                return sections.map(section => ({
                  ...section,
                  questions: groupByQuestionnaireSection[section.id] || [],
                }));
              }),
            );
          }),
          tap(sections => (this.totalQuestionCount = sections.reduce((a, b) => a + b.questions.length, 0))),
        );
    });
  }

  next(sections: QuestionnaireSection[], questions: Question[]): void {
    if (this.page === 'about') {
      this.page = 'questionnaire';
      this.questionnaireResponse$.pipe(first()).subscribe(qr => this.saveAboutDetails(qr));
    } else {
      this.currentQuestionIndex++;

      if (this.currentQuestionIndex >= questions.length) {
        this.currentQuestionIndex = 0;
        this.currentSectionIndex++;

        if (this.currentSectionIndex >= sections.length) {
          this.showSubmit = true;
          this.page = 'submit';
        }
      }
    }
    this.calculateProgress();
  }

  previous(): void {
    if (this.page === 'submit') {
      this.page = 'questionnaire';
    } else if (this.showAboutPage && this.currentQuestionIndex === 0 && this.currentSectionIndex === 0) {
      this.page = 'about';
    }

    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    } else {
      if (this.currentSectionIndex > 0) {
        this.currentSectionIndex--;

        this.questionnaireSections$.pipe(take(1)).subscribe((sections: QuestionnaireSection[]) => {
          const section = sections[this.currentSectionIndex];
          this.currentQuestionIndex = section.questions.length - 1;
        });
      }
    }

    this.calculateProgress();
  }

  submit(questionnaireResponse: QuestionnaireResponse): void {
    questionnaireResponse.submitted = true;
    questionnaireResponse.submittedOn = Date.now();
    questionnaireResponse.mode = questionnaireResponse.questionnaire.mode;

    this.questionnaireResponseService
      .updateOne$(questionnaireResponse.id, questionnaireResponse)
      .pipe(first())
      .subscribe({
        complete: () => {
          this.router.navigate(['../'], { relativeTo: this.route });
        },
        error: () => {
          questionnaireResponse.submitted = false;
          delete questionnaireResponse.submittedOn;
        },
      });
  }

  protected getNullableNumber(value: string) {
    return value ? Number(value) : null;
  }

  private saveAboutDetails(questionnaireResponse: QuestionnaireResponse): void {
    if (questionnaireResponse.submitted) {
      // Already submitted, cannot change about details.
      return;
    }

    questionnaireResponse.mode = questionnaireResponse.questionnaire.mode;

    this.questionnaireResponseService
      .updateOne$(questionnaireResponse.id, questionnaireResponse)
      .pipe(first())
      .subscribe();
  }

  private calculateProgress() {
    this.questionnaireSections$.pipe(first()).subscribe(sections => {
      const completedAboutCompany = this.page !== 'about';
      const completedSections = sections.slice(0, this.currentSectionIndex);

      const completedQuestions =
        completedSections.reduce((total, section) => total + section.questions.length, 0) +
        this.currentQuestionIndex +
        (this.showAboutPage && completedAboutCompany ? 1 : 0);

      const totalQuestions = this.totalQuestionCount + (this.showAboutPage ? 1 : 0);
      this.progress = this.totalQuestionCount > 0 ? Math.round((completedQuestions / totalQuestions) * 100) : 0;
    });
  }
}
