<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<ng-container *ngIf="question">
  <div *ngIf="questionResponse" class="mt-4">
    <div class="form-group">
      <div>{{ question.text }}</div>

      <ng-container *ngIf="question.type === getEnumKeyFromValue(QuestionType.TEXT)">
        <div *ngIf="editable">
          <textarea class="form-control" name="stext{{ questionResponse.id }}" [(ngModel)]="questionResponse.text" rows="4" required #stext="ngModel"></textarea>
          <div *ngIf="stext.dirty && stext.invalid" class="alert text-danger">
            <div *ngIf="stext.errors.required">A response is required</div>
          </div>
        </div>

        <div *ngIf="!editable">
          <div class="text-preserve-lines">{{ questionResponse.text }}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="question.type === getEnumKeyFromValue(QuestionType.SELECT)">
        <div *ngIf="editable">
          <ng-container *ngIf="question.multiple">
            <input type="hidden" name="options" [ngModel]="questionResponse.optionIds" required #options="ngModel" />
            <div *ngFor="let option of question.options; let i = index" class="form-check">
              <input type="checkbox" class="form-check-input" name="option{{ i }}" (change)="changeMultipleOption(questionResponse, option, $event.target.checked)" [value]="option.id" [checked]="questionResponse.optionIds?.includes(option.id)" #option{{i}} />
              <label class="form-check-label" for="option{{ i }}">{{ option.text }}</label>
            </div>
            <div *ngIf="options.dirty && options.invalid" class="alert text-danger">
              <div *ngIf="options.errors.required">A response is required</div>
            </div>
          </ng-container>
          <ng-container *ngIf="!question.multiple">
            <select class="form-control" name="soption{{ questionResponse.id }}" [(ngModel)]="questionResponse.optionId" required #soption="ngModel">
              <option *ngFor="let option of question.options" [ngValue]="option.id">{{ option.text }}</option>
            </select>
            <div *ngIf="soption.dirty && soption.invalid" class="alert text-danger">
              <div *ngIf="soption.errors.required">A response is required</div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="!editable">
          <ng-container *ngIf="question.multiple">
            <div class="text-preserve-lines">{{ displayOptions(question, questionResponse) }}</div>
          </ng-container>
          <ng-container *ngIf="!question.multiple">
            <div class="text-preserve-lines">{{ displayOption(question, questionResponse) }}</div>
          </ng-container>
        </div>

        <!-- TODO API: Currently web-api can only support 1 level of sub questions (sub question can't have further sub questions) -->
        <!-- <ng-container *ngFor="let option of question.options">
          <ng-container *ngIf="option.id === questionResponse.optionId && option.subQuestion">
            <app-sub-question-response [question]="option.subQuestion" [questionResponse]="questionResponse.subQuestionResponse" [editable]="editable"></app-sub-question-response>
          </ng-container>
        </ng-container> -->
      </ng-container>
    </div>
  </div>
</ng-container>
