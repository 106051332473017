<!--
 Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<article *ngIf="questionnaireResponse$ | async as qr" class="block">
  <article class="progress mb-4">
    <article class="progress-bar" role="progressbar" [attr.aria-valuenow]="progress" [style.width]="progress + '%'" aria-valuemin="0" aria-valuemax="100"></article>
  </article>

  <form *ngIf="page === 'about'" #form="ngForm" (submit)="next(null, null)">
    <h3 class="mb-4">About Your Company</h3>
    <em class="d-block mb-3">Throughout this document, 'the company' refers to the entity that is completing the questionnaire</em>
    <fieldset [attr.disabled]="qr.submitted || null">
      <section class="form-group">
        <label for="companyName">Legal Name of Company*</label>
        <input class="form-control" name="companyName" [(ngModel)]="qr.companyName" required #companyName="ngModel" />
        <section *ngIf="companyName.invalid && (companyName.dirty || companyName.touched)" class="alert text-danger">
          <p *ngIf="companyName.errors.required">Company name is required</p>
        </section>
      </section>
      <section class="form-group">
        <label for="companyIdentifiers">Unique Company Numbers or Identifiers (ABN, ACN, EIN or other)*</label>
        <input class="form-control" name="companyIdentifiers" [(ngModel)]="qr.companyIdentifiers" required #companyIdentifiers="ngModel" />
        <section *ngIf="companyIdentifiers.invalid && (companyIdentifiers.dirty || companyIdentifiers.touched)" class="alert text-danger">
          <p *ngIf="companyIdentifiers.errors.required">Company indentifiers are required</p>
        </section>
      </section>
      <section class="form-group d-grid columns-2 column-gap-4">
        <label for="primaryContactName" class="col-span-2">Best contact to complete the questionnaire*</label>
        <section class="flex-b-50">
          <input class="form-control" name="primaryContactName" [(ngModel)]="qr.primaryContactName" placeholder="Name" required #primaryContactName="ngModel" />
          <section *ngIf="primaryContactName.invalid && (primaryContactName.dirty || primaryContactName.touched)" class="alert text-danger">
            <p *ngIf="primaryContactName.errors.required">Primary contact is required</p>
          </section>
        </section>
        <section class="flex-b-50">
          <input class="form-control" name="primaryContactEmail" [(ngModel)]="qr.primaryContactEmail" type="email" placeholder="Email" required #primaryContactEmail="ngModel" />
          <section *ngIf="primaryContactEmail.invalid && (primaryContactEmail.dirty || primaryContactEmail.touched)" class="alert text-danger">
            <p *ngIf="primaryContactEmail.errors.required">Primary contact email is required</p>
          </section>
        </section>
      </section>
      <section class="form-group d-grid columns-2 column-gap-4">
        <label for="secondaryContactName" class="col-span-2">Secondary contact to complete the questionnaire</label>
        <input class="form-control flex-b-50" name="secondaryContactName" [(ngModel)]="qr.secondaryContactName" placeholder="Name" #secondaryContactName="ngModel" />
        <input class="form-control flex-b-50" name="secondaryContactEmail" [(ngModel)]="qr.secondaryContactEmail" type="email" placeholder="Email" #secondaryContactEmail="ngModel" />
      </section>
      <section class="form-group">
        <label for="employeeCount">Approximate Number of Employees*</label>
        <app-autocomplete class="form-control" name="employeeCount" [options]="[10, 25, 100, 250]" placeholder="Select or input a value" [ngModel]="qr.employeeCount" (ngModelChange)="qr.employeeCount = getNullableNumber($event)" required pattern="^[0-9]*$" #employeeCount="ngModel" />
        <section *ngIf="employeeCount.invalid && (employeeCount.dirty || employeeCount.touched)" class="alert text-danger">
          <p *ngIf="employeeCount.errors.required">Employee count is required</p>
          <p *ngIf="employeeCount.errors.pattern">Employee count must be a number</p>
        </section>
      </section>
      <section class="form-group">
        <label for="annualRevenue">Approximate Annual Revenue*</label>
        <input class="form-control" name="annualRevenue" [ngModel]="qr.annualRevenue" (ngModelChange)="qr.annualRevenue = getNullableNumber($event)" type="number" placeholder="Annual Revenue" required pattern="^[0-9]*$" #annualRevenue="ngModel" />
        <section *ngIf="annualRevenue.invalid && (annualRevenue.dirty || annualRevenue.touched)" class="alert text-danger">
          <p *ngIf="annualRevenue.errors.required">Annual revenue is required</p>
          <p *ngIf="annualRevenue.errors.pattern">Annual revenue must be a number</p>
        </section>
      </section>
      <section *ngIf="qr.questionnaire.mode === 'SLAVES'" class="form-group">
        <label for="regulationObligations">What Modern Slavery reporting regulations is your company subject to? (if any)</label>
        <app-multiple-options name="regulationObligations" [options]="obligationsOptions" [(ngModel)]="qr.regulationObligations" [allowAdd]="true" />
      </section>
      <section *ngIf="qr.questionnaire.mode === 'SLAVES'" class="form-group">
        <label for="restrictions">Which of the following apply to your company? (if any)</label>
        <app-multiple-options name="restrictions" [options]="restrictionsOptions" [(ngModel)]="qr.restrictions" />
      </section>
    </fieldset>
    <button type="submit" class="btn btn-outline mr-4" [disabled]="!qr.submitted && form.invalid">Next</button>
  </form>

  <ng-container *ngIf="page === 'questionnaire'">
    <div *ngFor="let section of questionnaireSections$ | async as sections; let s = index">
      <ng-container *ngIf="s === currentSectionIndex">
        <h3 class="text-uppercase mb-5">
          {{ section.name }} <span class="text-muted float-right">{{ s + 1 }}/{{ sections.length }}</span>
        </h3>

        <div *ngFor="let question of section.questions; let q = index">
          <ng-container *ngIf="q === currentQuestionIndex">
            <app-question-response [questionnaireResponseId]="qr.id" [questionId]="question.id" [editable]="!qr.submitted" [showPrevious]="showAboutPage || currentQuestionIndex > 0 || currentSectionIndex > 0" (next)="next(sections, section.questions)" (previous)="previous()" />
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <form *ngIf="page === 'submit' && !qr.submitted" class="mt-4" (ngSubmit)="submit(qr)" #form="ngForm">
    <p>Thank you. Your responses have been saved and are ready to be submitted. Once submitted, you'll no longer be able to modify your responses.</p>

    <div class="form-group form-check mt-4 mb-1">
      <input type="checkbox" class="form-check-input" id="shareable" name="shareable" [(ngModel)]="qr.shareable" />
      <label class="form-check-label" for="shareable">I consent to the sharing of my responses and the scores generated from my responses with other clients.</label>
    </div>

    <div class="mt-5">
      <button type="button" class="btn btn-outline mr-4" (click)="previous()">Back</button>
      <button type="submit" class="btn btn-primary mr-4 float-right">Submit</button>
      <button type="button" class="btn btn-outline mr-4 float-right" [routerLink]="['../']">I'll submit later</button>
    </div>
  </form>
</article>
