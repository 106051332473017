// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { QuestionSelect, QuestionSelectOption, QuestionType } from 'src/app/question/question.model';
import { getEnumKeyFromValue } from 'src/app/shared/util';

import {
  QuestionResponseSelect,
  QuestionResponseSelectMultiple,
  QuestionResponseText,
} from '../question-response.model';

@Component({
  selector: 'app-sub-question-response',
  templateUrl: './sub-question-response.component.html',
  styleUrls: ['./sub-question-response.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class SubQuestionResponseComponent {
  @Input()
  form: NgForm;

  @Input()
  question: QuestionSelect;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionResponse: QuestionResponseText | QuestionResponseSelect | any;

  @Input()
  editable: boolean;

  QuestionType = QuestionType;

  displayOption(question: QuestionSelect, questionResponse: QuestionResponseSelect): string {
    return question.options.find(o => o.id === questionResponse.optionId)?.text;
  }

  displayOptions(question: QuestionSelect, questionResponse: QuestionResponseSelectMultiple): string {
    return question.options
      .filter(o => questionResponse.optionIds?.some(ro => ro === o.id))
      ?.map((o: QuestionSelectOption) => o.text)
      .join('\n');
  }

  changeMultipleOption(
    questionResponse: QuestionResponseSelectMultiple,
    option: QuestionSelectOption,
    selected: boolean,
  ): void {
    if (!questionResponse.optionIds) {
      questionResponse.optionIds = [];
    }

    // Add the option
    if (selected && !questionResponse.optionIds.includes(option.id)) {
      questionResponse.optionIds.push(option.id);
    }

    // Remove the option
    if (!selected) {
      questionResponse.optionIds = questionResponse.optionIds.filter(o => o !== option.id);
    }

    // Delete the optionIds from the question response to fire the required validator on the form
    if (questionResponse.optionIds.length === 0) {
      delete questionResponse.optionIds;
    }

    this.form.controls.options.markAsDirty();
    this.form.controls.options.updateValueAndValidity();
  }

  getEnumKeyFromValue(value: QuestionType) {
    return getEnumKeyFromValue(QuestionType, value);
  }
}
